import React, { type FC } from "react"
import { NavLink } from "react-router-dom"
import { useGetProfileDataQuery } from "features/viewer"
import { LabeledSwitch } from "shared/ui"
import { type ForMeButtonProps } from "./types"

export const ForMeButton: FC<ForMeButtonProps> = (props) => {
    const { isTurnOn, handlerToggle } = props
    const { data } = useGetProfileDataQuery("")
    return (
        <>
            {!data ? (
                <NavLink to={"/auth"}>
                    <LabeledSwitch isNoWrap isTurnOn={false} title={"Для меня"} handlerToggle={() => {}} />
                </NavLink>
            ) : (
                <LabeledSwitch isTurnOn={isTurnOn} handlerToggle={handlerToggle} isNoWrap title={"Для меня"} />
            )}
        </>
    )
}
