import { type Dispatch, type SetStateAction, useEffect, useState } from "react"

export const useToggle = (initial: boolean, isNeedSetter?: boolean) => {
    const [isTurnOn, setTurnOn] = useState<boolean>(initial)
    const handlerToggle = () => {
        setTurnOn(!isTurnOn)
    }

    useEffect(() => {
        setTurnOn(initial)
    }, [initial])

    const result: { isTurnOn: boolean; handlerToggle: () => void; setTurnOn?: Dispatch<SetStateAction<boolean>> } = {
        isTurnOn,
        handlerToggle,
    }

    if (isNeedSetter) {
        result.setTurnOn = setTurnOn
    }
    return result
}
