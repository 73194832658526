import { useEffect, useState } from "react"
import { type CategoryItem, useGetCategoriesOpportunitiesQuery, useGetCategoriesPlacesQuery } from "features/user"
import { type SelectData } from "shared/ui"
import { useGetTypeParameters } from "../../lib"

const getSelectData = (placeData: CategoryItem[] = [], oppData: CategoryItem[] = [], type: 0 | 1 | 2 = 0) => {
    switch (type) {
        case 2:
            return oppData
        case 1:
            return placeData
        default:
            return [...placeData, ...oppData]
    }
}

export const useTotalOpportunitiesCategoryQuery = () => {
    const { data: placeData } = useGetCategoriesPlacesQuery({})
    const { data: oppData } = useGetCategoriesOpportunitiesQuery({})
    const { type } = useGetTypeParameters()
    return {
        placeData,
        oppData,
        selectData: getSelectData(placeData, oppData, type),
    }
}

export const useGetResultCategoriesFilters = (
    categories: string[],
    places?: CategoryItem[],
    opportunities?: CategoryItem[]
) => {
    const [categoriesList, setCategoriesList] = useState<{
        opCats?: SelectData[]
        placeCats?: SelectData[]
    }>({ opCats: [], placeCats: [] })
    useEffect(() => {
        setCategoriesList((prevState) => ({
            ...prevState,
            placeCats: places,
        }))
        setCategoriesList((prevState) => ({
            ...prevState,
            opCats: opportunities,
        }))
    }, [places, opportunities])
    return {
        placeCats: categoriesList?.placeCats?.flatMap((el) =>
            categories?.includes(el.id as string) ? (el.id as string) : []
        ) as string[],
        opCats: categoriesList?.opCats?.flatMap((el) =>
            categories?.includes(el.id as string) ? (el.id as string) : []
        ) as string[],
    }
}
