import React, { type FC } from "react"
import { useLocation } from "react-router-dom"
import { LabeledSwitch, Select } from "shared/ui"
import { sortSelect } from "../../config"
import { type ModalFiltersBodyProps } from "./types"

export const ModalFiltersBody: FC<ModalFiltersBodyProps> = (props) => {
    const { categories = [], initialValueSelectNew, isToggleForMe, toggler, needUpdate } = props
    if (isToggleForMe) {
        return null
    }
    const location = useLocation()
    let getStartCategories = categories.filter((el) => {
        const payload = new URLSearchParams(location.search)
        const opCats = payload.get("opCats")?.split(",")
        const placeCats = payload.get("placeCats")?.split(",")
        if (opCats && opCats.includes(el.id as string)) {
            return true
        }
        if (placeCats && placeCats.includes(el.id as string)) {
            return true
        }
        return false
    })

    return (
        <>
            <LabeledSwitch name={"onlyOpen"} {...toggler} title={"Только с открытой регистрацией"} />
            <Select
                setWasClear={() => {}}
                wasClear={needUpdate}
                data={sortSelect}
                labelText={"Сортировка"}
                name={"sort"}
                initialValue={initialValueSelectNew}
            />
            <Select
                start={location.search ? getStartCategories : []}
                hasPlaceholder={true}
                data={categories}
                labelText={"Категории"}
                name={"categories"}
                multiple
                setWasClear={() => {}}
                wasClear={needUpdate}
                clearable={true}
            />
        </>
    )
}
