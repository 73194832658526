import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { type CategoryItem } from "./types"

const categories_url = `${BASE_URL.PUBLIC}/categories`

export const categoriesApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getCategoriesPlaces: builder.query<CategoryItem[], unknown>({
            query: () => ({
                url: `${categories_url}/places`,
                method: "GET",
            }),
            transformResponse: (data: CategoryItem[]) => {
                return data.map(({ id, name }) => ({
                    id: `p${id}`,
                    name,
                }))
            },
        }),
        getCategoriesOpportunities: builder.query<CategoryItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${categories_url}/opportunities`,
                method: "GET",
            }),
            transformResponse: (data: CategoryItem[]) => {
                return data.map(({ id, name }) => ({
                    id: `o${id}`,
                    name,
                }))
            },
        }),
        getCategoriesTags: builder.query<CategoryItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${categories_url}/tags`,
                method: "GET",
            }),
        }),
        getCategoriesUsers: builder.query<CategoryItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${categories_url}/users`,
                method: "GET",
            }),
        }),
    }),
})

export const {
    useGetCategoriesPlacesQuery,
    useGetCategoriesOpportunitiesQuery,
    useGetCategoriesTagsQuery,
    useGetCategoriesUsersQuery,
} = categoriesApi
