import React, { type FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useGetProfileDataQuery } from "features/viewer"
import { Button, ButtonSize, ButtonType, Row } from "shared/ui"
import { FilterSvg } from "shared/assets/svg"
import { useModal, useToggle } from "shared/hook"
import { type FiltersBarProps, type IFiltersBarForm } from "./types"
import { StyledFiltersControl } from "./styles"
import { ModalFilters } from "./ModalFilters"
import { ForMeButton } from "./ForMeButton"
import { FiltersBarFormConfig } from "./config"

export const FiltersBar: FC<FiltersBarProps> = (props) => {
    const modal = useModal()
    const methods = useForm<IFiltersBarForm>(FiltersBarFormConfig)
    const { children, setFilters, wasLogin } = props
    const { isTurnOn, handlerToggle, setTurnOn } = useToggle(false, true)
    const { data } = useGetProfileDataQuery("")
    const toggleForMe = useToggle(wasLogin)
    useEffect(() => {
        toggleForMe.isTurnOn
            ? setFilters({
                  userCats: data?.userCategories.map((el) => el.id.toString()),
                  opCats: data?.interests.map((el) => el.id.toString()),
                  isToggleForMe: true,
              })
            : setFilters({})
    }, [data?.interests, data?.userCategories, toggleForMe.isTurnOn])
    return (
        <FormProvider {...methods}>
            <StyledFiltersControl>
                <Row>
                    <Button onClick={modal.toggleActivity} typeUI={ButtonType.GHOST} size={ButtonSize.L}>
                        <FilterSvg />
                        Фильтры
                    </Button>
                    <ForMeButton {...toggleForMe} />
                </Row>
            </StyledFiltersControl>
            <ModalFilters
                handlerOnlyRegReset={() => {
                    if (setTurnOn) {
                        setTurnOn(false)
                    }
                }}
                isToggleForMe={toggleForMe.isTurnOn}
                toggler={{ isTurnOn, handlerToggle }}
                setFilters={setFilters}
                {...modal}
            >
                {children}
                <ForMeButton {...toggleForMe} />
            </ModalFilters>
        </FormProvider>
    )
}
