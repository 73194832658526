import React, { type FC, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { BackButtonText, Modal } from "shared/ui"
import { StyledSection } from "shared/styles"
import { useGetResultCategoriesFilters, useTotalOpportunitiesCategoryQuery } from "./lib"
import { StyledModalDefaultFilters } from "./styles"
import { type ModalFiltersProps } from "./types"
import { FooterButtonsControl } from "./ui"
import { ModalFiltersBody } from "./ui/ModalFiltersBody"

export const ModalFilters: FC<ModalFiltersProps> = (props) => {
    const { isActive, toggleActivity, children, setFilters, handlerOnlyRegReset, isToggleForMe, toggler } = props
    const { handleSubmit, getValues, setValue } = useFormContext()
    const { sort, categories } = getValues()
    const { selectData, placeData, oppData } = useTotalOpportunitiesCategoryQuery()
    const { placeCats, opCats } = useGetResultCategoriesFilters(categories, placeData, oppData)
    const customHandleSubmit = handleSubmit((data) => {
        setFilters(
            !isToggleForMe
                ? {
                      sort,
                      placeCats: placeCats.map((el) => el.slice(1, el.length)),
                      opCats: opCats.map((el) => el.slice(1, el.length)),
                      onlyOpen: data.onlyOpen,
                  }
                : {}
        )
        toggleActivity()
    })

    const [needUpdateSelect, setNeedUpdate] = useState<boolean>(false)

    useEffect(() => {
        if (needUpdateSelect) {
            setValue("sort", "asc")
            handlerOnlyRegReset()
        }
    }, [needUpdateSelect])
    return (
        <Modal isActive={isActive} toggleActivity={toggleActivity} bigHeight position={"right"}>
            <StyledModalDefaultFilters
                className={props.className}
                back={toggleActivity}
                title={"Фильтр возможностей"}
                footerBtn={() => (
                    <FooterButtonsControl
                        setNeedUpdate={setNeedUpdate}
                        placeCats={placeCats}
                        opCats={opCats}
                        customHandleSubmit={customHandleSubmit}
                    />
                )}
                backButtonText={BackButtonText.CANCEL}
                additionalHeadComponent={() => <StyledSection>{children}</StyledSection>}
            >
                <ModalFiltersBody
                    needUpdate={needUpdateSelect}
                    isToggleForMe={isToggleForMe}
                    toggler={toggler}
                    categories={selectData}
                    initialValueSelectNew={sort === "asc" ? "Сначала новые" : "Сначала старые"}
                />
            </StyledModalDefaultFilters>
        </Modal>
    )
}
