import styled from "styled-components"

const StyledPartners = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 32px;
`
const StyledPartnersList = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: var(--layout-mobile-section-gap);
`
export { StyledPartners, StyledPartnersList }
